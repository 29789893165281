var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c("j-vxe-table", {
        staticStyle: { "margin-bottom": "8px" },
        attrs: {
          toolbar: "",
          toolbarConfig: _vm.toolbarConfig,
          "row-number": "",
          "row-selection": "",
          "row-selection-type": "radio",
          "highlight-current-row": "",
          "click-select-row": "",
          height: _vm.tableHeight,
          loading: _vm.table1.loading,
          columns: _vm.table1.columns,
          dataSource: _vm.table1.dataSource,
          pagination: _vm.table1.pagination,
          "expand-config": _vm.expandConfig,
        },
        on: {
          pageChange: _vm.handleTable1PageChange,
          selectRowChange: _vm.handleTable1SelectRowChange,
        },
      }),
      _c(
        "a-tabs",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.subTabs.show,
              expression: "subTabs.show",
            },
          ],
          class: { "sub-tabs": true, "un-expand": !_vm.subTabs.expand },
        },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "子表1" } },
            [
              _c("j-vxe-table", {
                attrs: {
                  toolbar: "",
                  "row-number": "",
                  "row-selection": "",
                  height: "auto",
                  maxHeight: 350,
                  loading: _vm.table2.loading,
                  columns: _vm.table2.columns,
                  dataSource: _vm.table2.dataSource,
                  pagination: _vm.table2.pagination,
                },
                on: {
                  pageChange: _vm.handleTable2PageChange,
                  selectRowChange: _vm.handleTable2SelectRowChange,
                },
              }),
            ],
            1
          ),
          _c("a-tab-pane", { key: "2", attrs: { tab: "子表2" } }, [
            _c("h1", [_vm._v("这里是子表2")]),
            _c("h1", [_vm._v("这里是子表2")]),
            _c("h1", [_vm._v("这里是子表2")]),
            _c("h1", [_vm._v("这里是子表2")]),
            _c("h1", [_vm._v("这里是子表2")]),
            _c("h1", [_vm._v("这里是子表2")]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }